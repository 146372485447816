//import { build$ } from "protractor/built/element";
(function(window) {
    window.__env = window.__env || {};

    // API url


     window.__env.BASE_URL = 'https://ciplaapi.spikyarc.com/api/';
     window.__env.BASE_URL_LOGIN = 'https://ciplaapi.spikyarc.com/';
     window.__env.PHOTO_URL = 'https://ciplaapi.spikyarc.com/Documents/Photos/LabourPhotos/';
     window.__env.DOC_URL = 'https://ciplaapi.spikyarc.com/Documents/TaskDocuments/';
     
    //  window.__env.BASE_URL = 'https://ciplaapi2.spikyarc.com/api/';
    //  window.__env.BASE_URL_LOGIN = 'https://ciplaapi2.spikyarc.com/';
    //  window.__env.PHOTO_URL = 'https://ciplaapi2.spikyarc.com/Documents/Photos/LabourPhotos/';
    //  window.__env.DOC_URL = 'https://ciplaapi2.spikyarc.com/Documents/TaskDocuments/';



    //  window.__env.BASE_URL = 'https://localhost:59557/api/';
    //  window.__env.BASE_URL_LOGIN = 'https://localhost:59557/';
    //  window.__env.PHOTO_URL = 'https://localhost:59557/Documents/Photos/LabourPhotos/';
    //  window.__env.DOC_URL = 'https://localhost:59557/Documents/TaskDocuments/';

    // window.__env.BASE_URL = 'https://192.168.29.210:5501/api/';
    //  window.__env.BASE_URL_LOGIN = 'https://192.168.29.210:5501/';
    //  window.__env.PHOTO_URL = 'https://192.168.29.210:5501/Documents/Photos/LabourPhotos/';
    //  window.__env.DOC_URL = 'https://192.168.29.210:5501/Documents/TaskDocuments/';



    window.__env.enableDebug = true;
    window.__env.idelTime = 10; // default 10 , to change idle time (in minutes)
    window.__env.url= 'www.google.com'
}(this));